<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">我的报餐</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>
    <div class="content">
      <div class="apply-btn" @click="toReport">报餐申请</div>

      <div v-if="list && list.length > 0" class="reverse">
        <div v-for="(item, index) in list" :key="index" class="dish-card">
          <div class="card">
            <div class="eat-time" style="padding: 14.5px 16px; padding-left: 0">
              <div
                class="left"
                style="font-size: 16px; font-weight: 700; color: #333"
              >
                状态
              </div>
              <div :class="item.status === 1 ? 'agree' : 'refuse'">
                {{ item.status === 1 ? '成功' : '取消' }}
              </div>
            </div>
            <div class="eat-time">
              <div class="left">报餐时间</div>
              <div class="right">{{ item.day }}</div>
            </div>
            <div class="eat-time">
              <div class="left">报餐餐段</div>
              <div class="right">{{ getMealPeriod(item) }}</div>
            </div>
            <div class="eat-time">
              <div class="left">申请时间</div>
              <div class="right">{{ item.createTime }}</div>
            </div>

            <div v-if="item.status === 1" class="button">
              <div
                style="
                  position: absolute;
                  right: 15px;
                  display: flex;
                  align-items: center;
                  box-sizing: border-box;
                "
              >
                <div class="un-refuse" @click="changeStatus(item.id)">取消</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-empty
        v-else
        description="暂无数据"
        :image="require('@/assets/apply-list-none.png')"
        style="padding-top: 40%"
        class="empty"
      />
    </div>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import { getList, cancelApply } from '@/api/report-meal';
import cancel from '@/assets/cancel.png';
export default {
  name: 'CheckWork',
  data() {
    return {
      title: '',
      show: false,
      cancel,
      list: [],
      loading: false,
      finished: false,

      isClassManager: false
    };
  },
  computed: {
    date() {
      return (day) => {
        if (day) {
          return day.substr(-8);
        } else {
          return '-';
        }
      };
    }
  },
  created() {
    const { role } = this.$store.getters;
    this.title = role === 0 ? '主管' : '班主任';
    this.isClassManager = JSON.parse(
      sessionStorage.getItem('personInfo')
    ).isClassManager;
    // const { current, size } = this.pagination
    // if (this.isClassManager) {
    //   console.log('班主任');
    //   this.pagination.channel = 1;
    // }
    this.fetch();
  },
  methods: {
    toReport() {
      this.$router.push({
        name: 'ReportMealApply'
      });
    },
    async fetch() {
      const { list } = this;
      list.length === 0 && (this.show = true);
      const { code, data, message } = await getList();
      if (code === 0) {
        this.list = data;

        this.show = false;
      } else {
        console.log(message);
      }
    },
    toHome() {
      this.$router.push({ name: 'Home' });
    },
    onLoad() {
      const { current, size, total } = this.pagination;
      if (current * size >= total) {
        this.finished = true;
      } else {
        this.fetch({ current: current + 1, size });
      }
    },
    toBack() {
      this.$router.back();
    },
    // 获取餐段
    getMealPeriod(card) {
      const mealPeriodList = [];
      for (const key in card) {
        this.constant.SUBMIT_MEAL_PERIOD.forEach((item) => {
          if (item.value === key && card[key] === 1) {
            mealPeriodList.push(item.label);
          }
        });
      }
      return mealPeriodList.join('、');
    },
    // 更改状态
    async changeStatus(id) {
      try {
        this.show = true;
        const res = await cancelApply({ id });
        if (res.code === 0 && res.data) {
          this.$toast('取消成功');
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.show = false;
        await this.fetch();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  font-family: 'PingFangSC-Regular';
  height: 100%;
  background-color: #f7f7f7;

  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 44px;
    color: white;
    overflow: hidden;
    /* border-bottom: 1px solid rgba(181, 201, 210, 0.6); */
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .content {
    padding: 54px 16px 0 16px;
    .apply-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      width: 343px;
      height: 44px;
      background-color: #fa5050;
      color: #fff;
      border-radius: 4px;
    }
    .apply-btn-both {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      .person-btn {
        width: 164px;
        height: 44px;
        background-color: #ffa900;
        color: #fff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .reverse {
      display: flex;
      flex-direction: column;
      .dish-card {
        padding-bottom: 1.5rem;
      }
    }
    .title {
      display: flex;
      align-items: center;
      //padding: 0.5rem 1.5rem .4rem;
      padding-bottom: 0.5rem;
      text-align: center;
      & > .section {
        display: flex;
        justify-content: flex-end;
        span {
          padding: 0.1rem;
          border: 1px solid #1989fa;
          color: #1989fa;
          border-radius: 50%;
          font-size: 0.875rem;
          background-color: #fff;
          margin-left: 2rem;
          width: 1.3rem;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      div {
        width: 33.3%;
        font-weight: 600;
      }
    }
    .card {
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 10px;
      padding: 0 0 0 16px;
      box-shadow: 0 0 0.875rem #dcdcdc;
      display: flex;
      flex-direction: column;
      .cancel-end {
        display: flex;
        justify-content: flex-end !important;
        padding-top: 0 !important;
      }
      & > .dishes {
        border-bottom: 1px dashed #ccc;
        .dish {
          padding: 0.5rem 0;
          justify-content: space-between;
          .common-center {
            display: flex;
            align-items: center;
          }
        }
        //display: flex;
      }
      & > .eat-time {
        position: relative;
        margin-top: 14px;
        padding: 0px 16px 14px 0px;
        //color: #07c160;
        display: flex;
        align-items: start;
        justify-content: space-between;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        font-family: 'PingFangSC-Regular';
        .left {
          font-weight: 400;
          min-width: 64px;
        }
        .right {
          font-weight: 400;
          color: #666666;
          max-width: 176px;
          text-align: right;
          word-wrap: break-word; /* 或者使用 overflow-wrap: break-word; */
        }
      }
      .eat-time:first-child {
        margin-top: 0px;
      }
      .eat-time:first-child::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #e5e5e5;
        transform: scaleY(0.5);
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .footer {
        padding-top: 0.5rem;
        //border-top: 1px dashed #ccc;
        ::v-deep .cancel {
          font-size: 0.875rem !important;
        }
        .btn {
          background-color: #ff9460;
          color: #fff;
          padding: 0.3rem 1rem;
          border-radius: 0.3rem;
          font-size: 0.875rem;
          font-weight: normal;
          display: inline-block;
        }
      }
    }
    .show-qs {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.agree,
.applying,
.refuse {
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  font-family: 'PingFangSC-Regular';
  box-sizing: border-box;
  padding: 4px 8px;
}
.agree {
  background-color: #00b893;
}
.applying {
  background-color: #ffa900;
}
.refuse {
  background-color: #ffa900;
}
.empty {
  ::v-deep .van-empty__image {
    width: 280px;
    height: 150px;
  }
}
.button {
  position: relative;
  box-sizing: border-box;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
.un-refuse {
  width: 77px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #fa5050;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
</style>
